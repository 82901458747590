import categories from './categories';
import cards from './cards';
import router from './router';
import {
  CATEGORY_PREFIX, ROUTE_ALL_CATEGORIES, ROUTE_KINDS,
} from './routes';
import { playAudio, SOUNDS } from './audio';
import stats from './statistics';
import { difficultCards } from './difficult';

const APP_MODES = {
  train: 'Train',
  play: 'Play',
};

const PLAY_MODE_CLASS = 'play-mode';

const DISABLED_CLASS = 'disabled';

const getCardData = (cardDiv) => JSON.parse(cardDiv.getAttribute('card-data'));

class Play {
  constructor() {
    this.mode = null;
    this.gameOn = false;
    this.categoryIndex = null;
  }

  init(winImg, loseImg) {
    router.addEventListener('routechange', () => this.reset());

    this.playSwitcher = document.getElementById('play-switcher');
    this.playSwitcher.addEventListener('click', () => {
      this.playMode = this.playSwitcher.classList.contains('checked');
      this.reset();
    });

    this.playRepeatBtn = document.getElementById('play-repeat-btn');
    this.playRepeatBtn.addEventListener('click', () => {
      if (this.gameOn) {
        this.repeatWord();
      } else {
        this.start();
      }
    });

    this.cardDivs = [];
    this.checkCardDivs();

    this.splashScreen = document.getElementById('splash-screen');
    this.winImg = winImg;
    this.loseImg = loseImg;
    this.scoreBoard = document.getElementById('scoreboard');
  }

  set playMode(mode) {
    this.mode = mode;
    this.playSwitcher.textContent = mode ? APP_MODES.play : APP_MODES.train;
    document.body.classList.toggle(PLAY_MODE_CLASS, mode);
  }

  get playMode() {
    return this.mode;
  }

  get currentCards() {
    return cards[this.categoryIndex] || difficultCards;
  }

  get currentCard() {
    return this.currentCards[this.randomIndex[this.currentIndex]];
  }

  checkCardDivs() {
    const newDivs = document.querySelectorAll('.word-card');
    newDivs.forEach((div) => {
      if (!this.cardDivs.includes(div)) {
        this.cardDivs.push(div);
        const cardFront = div.querySelector('.card-front');
        const cardInner = div.querySelector('.card-inner');
        const card = getCardData(div);

        cardFront.addEventListener('click', () => {
          if (this.playMode) {
            if (this.gameOn) {
              this.checkWord(div);
            }
          } else if (!cardInner.classList.contains('flipped')) {
            playAudio(card.audioSrc);
          }
        });
      }
    });
  }

  reset() {
    this.playRepeatBtn.textContent = 'Start';
    this.playRepeatBtn.classList.remove('repeat');
    this.gameOn = false;
    this.checkCardDivs();
    this.cardDivs.forEach((div) => {
      div.classList.remove(DISABLED_CLASS);
    });
    this.scoreBoard.replaceChildren();
    this.wrongGuesses = false;
  }

  start() {
    const generateRandomIndex = (length) => {
      const randInd = [];
      while (randInd.length < length) {
        const rand = Math.floor(Math.random() * length);
        if (!randInd.includes(rand)) {
          randInd.push(rand);
        }
      }
      return randInd;
    };

    if (router.routeKind === ROUTE_KINDS.CATEGORY) {
      this.playRepeatBtn.textContent = 'Repeat';
      this.playRepeatBtn.classList.add('repeat');

      const category = router.currentPage.replace(`#${CATEGORY_PREFIX}`, '');
      this.categoryIndex = categories.findIndex((item) => item.link === category);
      this.randomIndex = generateRandomIndex(this.currentCards.length);
      this.currentIndex = -1;
      this.gameOn = true;
      this.nextWord();
    }
  }

  async repeatWord() {
    await playAudio(this.currentCard.audioSrc);
  }

  async nextWord() {
    this.currentIndex += 1;
    if (this.currentIndex < this.currentCards.length) {
      return this.repeatWord();
    }
    return this.gameOver();
  }

  async checkWord(cardDiv) {
    const addThumbsUp = (down = false) => {
      const thumbs = document.createElement('div');
      thumbs.classList.add('score-item');
      thumbs.classList.toggle('down', down);
      this.scoreBoard.prepend(thumbs);
    };

    if (!cardDiv.classList.contains(DISABLED_CLASS)) {
      const data = getCardData(cardDiv);
      const correct = this.currentCard.word === data.word;
      addThumbsUp(!correct);
      if (correct) {
        cardDiv.classList.add(DISABLED_CLASS);
        stats.incCorrect(this.currentCard.word);
        await playAudio(SOUNDS.correct);
        this.nextWord();
      } else {
        this.wrongGuesses = true;
        stats.incWrong(this.currentCard.word);
        await playAudio(SOUNDS.wrong);
      }
    }
  }

  async gameOver() {
    const currentPage = document.querySelector(router.currentPage);
    currentPage.style.display = 'none';
    const img = this.splashScreen.querySelector('img');
    img.src = this.wrongGuesses ? this.loseImg : this.winImg;
    this.splashScreen.style.display = 'flex';
    await playAudio(this.wrongGuesses ? SOUNDS.lose : SOUNDS.win);
    this.splashScreen.style.display = 'none';
    this.reset();
    window.location.hash = ROUTE_ALL_CATEGORIES.href;
  }
}

const play = new Play();

export default play;
