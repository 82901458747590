import {
  routes, ROUTE_ALL_CATEGORIES, ROUTE_STATISTICS, ROUTE_KINDS,
} from './routes';

const MAIN_TITLE = 'English for Kids';

class Router extends EventTarget {
  constructor() {
    super();
    this.routeKind = null;
    this.currentPage = null;
    this.subTitle = document.getElementById('subtitle');
    this.subTitleImg = document.getElementById('subtitle-img');
    window.addEventListener('hashchange', () => this.hashChange());
  }

  hashChange() {
    const newPage = window.location.hash;
    if (this.currentPage !== newPage) {
      this.loadPage(newPage);
    }
  }

  loadPage(newPage) {
    const page = routes.find(({ href }) => href === newPage)
                || routes[0];
    document.title = `${MAIN_TITLE} | ${page.title}`;
    this.subTitle.textContent = page.title;
    this.subTitleImg.style.display = page.img ? 'block' : 'none';
    this.subTitleImg.src = page.img;

    const event = new CustomEvent('routechange', { detail: page.href });
    this.dispatchEvent(event);

    routes.forEach((route) => {
      const pageDiv = document.getElementById(route.href.replace('#', ''));
      if (pageDiv) {
        pageDiv.style.display = route.href === page.href ? 'flex' : 'none';
      }
    });

    this.currentPage = page.href;

    switch (this.currentPage) {
      case ROUTE_ALL_CATEGORIES.href:
        this.routeKind = ROUTE_KINDS.ALL;
        break;
      case ROUTE_STATISTICS.href:
        this.routeKind = ROUTE_KINDS.STATS;
        break;
      default:
        this.routeKind = ROUTE_KINDS.CATEGORY;
        break;
    }
    Object.values(ROUTE_KINDS).forEach((kind) => {
      document.body.classList.toggle(kind, this.routeKind === kind);
    });
  }
}

const router = new Router();

export default router;
