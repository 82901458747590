import { routes } from './routes';
import router from './router';

const MENU_OPEN_CLASS = 'menu-open';

class Menu {
  constructor() {
    this.root = document.getElementById('menu');
    this.toggleButton = document.getElementById('menu-toggle-btn');
    this.toggleButton.addEventListener('click', () => this.toggle());

    this.open = false;

    // Close menu on click unless target is the menu itself
    window.addEventListener('click', (event) => {
      if (!['nav.side-menu', '#menu-toggle-btn']
        .some((c) => event.target.closest(c))) {
        this.hide();
      }
    });

    // Close on escape button
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.hide();
      }
    });
  }

  populate() {
    const frag = document.createDocumentFragment();
    this.links = [];

    routes.forEach(({
      title, href, img, hidden,
    }) => {
      if (!hidden) {
        const li = document.createElement('li');
        const link = document.createElement('a');
        link.textContent = title;
        link.href = href;
        link.addEventListener('click', () => this.hide());
        this.links.push(link);
        if (img) {
          const icon = new Image();
          icon.src = img;
          link.prepend(icon);
        }
        li.append(link);
        frag.append(li);
      }
    });
    this.checkStatus(window.location.hash);
    this.root.replaceChildren(frag);
    router.addEventListener('routechange', (event) => {
      this.checkStatus(event.detail);
    });
  }

  show() {
    if (!this.open) {
      document.body.classList.add(MENU_OPEN_CLASS);
      this.open = true;
    }
  }

  hide() {
    if (this.open) {
      document.body.classList.remove(MENU_OPEN_CLASS);
      this.open = false;
    }
  }

  toggle() {
    if (this.open) {
      this.hide();
    } else {
      this.show();
    }
  }

  checkStatus(href) {
    this.links.forEach((link) => {
      link.classList.toggle('current', link.hash === href);
    });
  }
}

const menu = new Menu();

export default menu;
