import './styles/main.scss';
import './assets/images/menu.svg';
import './assets/images/close.svg';
import './assets/images/flip.svg';
import './assets/images/thumbs-up.svg';
import './assets/images/draw.png';
import './assets/images/read.png';
import './assets/images/favicon.png';
import './assets/images/start.png';
import './assets/images/repeat.png';
import winImg from './assets/images/win-sticker.png';
import loseImg from './assets/images/lose-sticker.png';

import router from './modules/router';
import menu from './modules/menu';
import { renderPages, initCheckBoxes } from './modules/render';
import play from './modules/play-mode';
import stats from './modules/statistics';
import { initDifficult } from './modules/difficult';
import theme from './modules/theme';

const App = () => {
  menu.populate();
  renderPages();
  stats.init();
  initCheckBoxes();
  initDifficult();
  play.init(winImg, loseImg);
  router.hashChange();
  theme.init();
};

App();
