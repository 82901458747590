const categories = [
  {
    title: 'Actions (set A)',
    link: 'action-set-a',
    img: 'img/action1.png',
  },
  {
    title: 'Actions (set B)',
    link: 'action-set-b',
    img: 'img/action2.png',
  },
  {
    title: 'Animals (set A)',
    link: 'animal-set-a',
    img: 'img/animal1.png',
  },
  {
    title: 'Animals (set B)',
    link: 'animal-set-b',
    img: 'img/animal2.png',
  },
  {
    title: 'Fruits',
    link: 'fruits',
    img: 'img/fruit.png',
  },
  {
    title: 'Vegetables',
    link: 'vegetables',
    img: 'img/vegetable.png',
  },
  {
    title: 'Clothes',
    link: 'clothes',
    img: 'img/clothes.png',
  },
  {
    title: 'Emotions',
    link: 'emotions',
    img: 'img/emotions.png',
  },
];

export default categories;
