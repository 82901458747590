import { ROUTE_REPEAT_DIFFICULT } from './routes';
import { renderCategory } from './render';
import { sortWords, SORT_MODE, wordList } from './sort-words';
import cards from './cards';
import router from './router';

const difficultCards = [];

const renderDifficultCards = (cardList) => {
  const root = document.querySelector('main');

  const difficultCategory = {
    title: ROUTE_REPEAT_DIFFICULT.title,
    link: 'repeat-difficult',
  };

  const newDiv = renderCategory(difficultCategory, cardList);
  const oldDiv = document.querySelector(ROUTE_REPEAT_DIFFICULT.href);
  if (oldDiv) {
    oldDiv.replaceWith(newDiv);
  } else {
    root.append(newDiv);
  }
};

const renderDifficult = () => {
  let dfCards = sortWords(wordList, 'correctPercentage', SORT_MODE.ascending);
  dfCards = dfCards.filter((card) => card.correctPercentage > 0 && card.correctPercentage < 100);
  dfCards = dfCards.slice(0, 8);
  const allCards = cards.flat(1);
  dfCards = dfCards.map((card) => allCards.find((item) => item.word === card.word));
  renderDifficultCards(dfCards);
  difficultCards.splice(0, difficultCards.length);
  difficultCards.push(...dfCards);
};

const initDifficult = () => {
  document.getElementById('repeat-difficult-btn').addEventListener('click', () => {
    window.location.hash = ROUTE_REPEAT_DIFFICULT.href;
  });
  router.addEventListener('routechange', (event) => {
    if (event.detail === ROUTE_REPEAT_DIFFICULT.href) renderDifficult();
  });
};

export { initDifficult, difficultCards };
