class Theme {
  constructor() {
    this.themeSwitch = document.getElementById('theme-switcher');
    this.darkMode = false;
  }

  init() {
    this.themeSwitch.addEventListener('click', () => {
      this.setTheme(this.themeSwitch.classList.contains('checked'));
      this.themeSwitch.textContent = this.darkMode ? 'Dark' : 'Light';
    });
  }

  setTheme(mode) {
    this.darkMode = mode;
    document.body.classList.toggle('dark-theme', this.darkMode);
  }
}

const theme = new Theme();

export default theme;
