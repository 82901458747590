const SOUNDS = {
  correct: {
    src: './audio/correct-answer.mp3',
  },
  wrong: {
    src: './audio/wrong-answer.mp3',
  },
  win: {
    src: './audio/win.mp3',
  },
  lose: {
    src: './audio/lose.mp3',
  },
};

const playAudio = (link) => {
  const src = link.src || link;
  const audio = new Audio(src);

  return new Promise((res) => {
    audio.play();
    audio.onended = res;
  });
};

export { playAudio, SOUNDS };
