import categories from './categories';
import cards from './cards';
import { CATEGORY_PREFIX, ROUTE_ALL_CATEGORIES } from './routes';
import stats from './statistics';

const root = document.querySelector('main');

const renderCard = (card, isCategory = false) => {
  const cardDiv = document.createElement('div');
  cardDiv.id = card.word;
  cardDiv.classList.add('card');
  if (!isCategory) {
    cardDiv.classList.add('word-card');
    cardDiv.setAttribute('card-data', JSON.stringify(card));
  }

  const cardInner = document.createElement('div');
  cardInner.classList.add('card-inner');

  // Card Front
  const cardFront = document.createElement('div');
  cardFront.classList.add('card-front');

  const cardImage = new Image();
  if (isCategory) {
    cardImage.src = cards[categories.findIndex((category) => category.link === card.link)][0].image;
  } else {
    cardImage.src = card.image;
  }

  const cardDescription = document.createElement('div');
  cardDescription.classList.add('card-description');
  const title = document.createElement('h3');
  title.textContent = isCategory ? card.title : card.word;
  cardDescription.append(title);
  let flipBtn;
  if (!isCategory) {
    flipBtn = document.createElement('button');
    cardDescription.append(flipBtn);
  }
  cardFront.append(cardImage, cardDescription);

  cardInner.append(cardFront);
  if (isCategory) {
    cardFront.addEventListener('click', () => {
      window.location.hash = `#${CATEGORY_PREFIX}${card.link}`;
    });
  } else {
    // Card Back
    const cardBack = document.createElement('div');
    cardBack.classList.add('card-back');
    const wordTranslation = document.createElement('h3');
    wordTranslation.textContent = card.translation;
    cardBack.append(wordTranslation);

    // Flip Card on Click
    flipBtn.addEventListener('click', () => {
      cardInner.classList.add('flipped');
      stats.incViews(card.word);
      cardInner.addEventListener('mouseleave', () => {
        cardInner.classList.remove('flipped');
      }, { once: true });
    });
    cardInner.append(cardBack);
  }

  cardDiv.append(cardInner);
  return cardDiv;
};

const renderCategory = (category, categoryCards) => {
  const categoryDiv = document.createElement('div');
  categoryDiv.id = CATEGORY_PREFIX + category.link;
  categoryDiv.classList.add('category');
  categoryDiv.style.display = 'none';
  categoryCards.forEach((card) => {
    categoryDiv.append(renderCard(card));
  });
  return categoryDiv;
};

const renderAllCategories = () => {
  const categoryDiv = document.createElement('div');
  categoryDiv.id = ROUTE_ALL_CATEGORIES.href.replace('#', '');
  categoryDiv.classList.add('category');
  categoryDiv.style.display = 'none';
  categories.forEach((card) => {
    categoryDiv.append(renderCard(card, true));
  });
  return categoryDiv;
};

const renderPages = () => {
  const renderCategoryIndex = (index) => renderCategory(categories[index], cards[index]);

  const frag = document.createDocumentFragment();
  frag.append(renderAllCategories());
  categories.forEach((_, index) => {
    frag.append(renderCategoryIndex(index));
  });
  root.append(frag);
};

const initCheckBoxes = () => {
  document.querySelectorAll('.check-box').forEach((checkbox) => {
    checkbox.addEventListener('click', () => {
      checkbox.classList.toggle('checked');
    });
  });
};

export { renderPages, renderCategory, initCheckBoxes };
