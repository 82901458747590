import { setStorageItem } from './local-storage';

import {
  SORT_MODE, sortWords, wordList, resetWordList, STATS_STORAGE_KEY, findWordIndex,
} from './sort-words';

class Stats {
  updateTable() {
    const frag = document.createDocumentFragment();
    const wl = sortWords(wordList, this.sortHeader, this.sortMode);
    wl.forEach((item) => {
      const row = document.createElement('tr');
      row.append(...Object.values(item).map((entry) => {
        const cell = document.createElement('td');
        cell.textContent = entry;
        return cell;
      }));
      frag.append(row);
    });
    this.root.replaceChildren(frag);
  }

  init() {
    this.root = document.querySelector('#stats tbody');
    this.resetBtn = document.getElementById('reset-stats-btn');
    this.resetBtn.addEventListener('click', () => {
      resetWordList();
      this.updateTable();
    });

    this.headers = document.querySelectorAll('#stats th');
    this.headers.forEach((header) => {
      header.addEventListener('click', () => {
        let sortMode = SORT_MODE.ascending;
        if (header.classList.contains('ascending')) {
          sortMode = SORT_MODE.descending;
        } else
        if (header.classList.contains('descending')) {
          sortMode = SORT_MODE.none;
        }
        this.sortMode = sortMode;
        this.sortHeader = header.id;

        this.headers.forEach((item) => {
          item.classList.toggle(
            SORT_MODE.ascending,
            item.id === this.sortHeader && sortMode === SORT_MODE.ascending,
          );
          item.classList.toggle(
            SORT_MODE.descending,
            item.id === this.sortHeader && sortMode === SORT_MODE.descending,
          );
        });
        this.updateTable();
      });
    });

    this.updateTable();
  }

  updateWord(word, views = 0, correct = 0, wrong = 0) {
    const index = findWordIndex(word);
    if (index >= 0) {
      wordList[index].views += views;
      wordList[index].correct += correct;
      wordList[index].wrong += wrong;
      const guesses = wordList[index].correct + wordList[index].wrong;
      wordList[index].correctPercentage = guesses === 0 ? 0
        : Math.round((wordList[index].correct / guesses) * 100);
    }
    setStorageItem(STATS_STORAGE_KEY, wordList);
    this.updateTable();
  }

  incViews(word) {
    this.updateWord(word, 1, 0, 0);
  }

  incCorrect(word) {
    this.updateWord(word, 0, 1, 0);
  }

  incWrong(word) {
    this.updateWord(word, 0, 0, 1);
  }
}

const stats = new Stats();

export default stats;
