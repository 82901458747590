const setStorageItem = (itemKey, itemValue) => {
  if (window.localStorage && window.localStorage.setItem) {
    window.localStorage.setItem(itemKey, JSON.stringify(itemValue));
  }
};

const getStorageItem = (itemKey) => {
  if (window.localStorage && window.localStorage.getItem) {
    const item = window.localStorage.getItem(itemKey);
    try {
      return JSON.parse(item);
    } catch (error) {
      return undefined;
    }
  } else return undefined;
};

const removeStorageItem = (itemKey) => {
  if (window.localStorage && window.localStorage.getItem) {
    window.localStorage.removeItem(itemKey);
  }
};

export { setStorageItem, getStorageItem, removeStorageItem };
