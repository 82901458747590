import cards from './cards';
import categories from './categories';
import { getStorageItem, removeStorageItem } from './local-storage';

const STATS_STORAGE_KEY = '5ba2c69a-fd0a-4465-a794-5f99ea578794';

const SORT_MODE = {
  none: 'none',
  ascending: 'ascending',
  descending: 'descending',
};

const sortWords = (wordList, sortHeader, sortMode) => {
  if (sortHeader && sortMode !== SORT_MODE.none) {
    const sorted = wordList.slice();
    return sorted.sort((item1, item2) => {
      let a = item1[sortHeader];
      let b = item2[sortHeader];
      a = parseFloat(a) || a;
      b = parseFloat(b) || b;
      if (a < b) {
        return sortMode === SORT_MODE.ascending ? -1 : 1;
      }
      if (a > b) {
        return sortMode === SORT_MODE.ascending ? 1 : -1;
      }
      return 0;
    });
  }
  return wordList;
};

const wordList = [];

const resetWordList = () => {
  let wl = [];
  categories.forEach((category, index) => {
    const categoryIndex = cards[index].map((card) => ({
      word: card.word,
      translation: card.translation,
      category: category.title,
      views: 0,
      correct: 0,
      wrong: 0,
      correctPercentage: 0,
    }));
    wl = wl.concat(categoryIndex);
  });
  wordList.splice(0, wordList.length);
  wordList.push(...wl);
  removeStorageItem(STATS_STORAGE_KEY);
};

const findWordIndex = (word) => wordList.findIndex((item) => item.word === word);

const stored = getStorageItem(STATS_STORAGE_KEY);
if (stored) { wordList.push(...stored); } else { resetWordList(); }

export {
  SORT_MODE, sortWords, wordList, resetWordList, STATS_STORAGE_KEY, findWordIndex,
};
